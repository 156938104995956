(<template>
  <article class="welcome-modal">
    <div class="welcome-modal__logo-cont">
      <div class="welcome-modal__logo"></div>
    </div>
    <p class="welcome-modal__main-text">{{ $gettext('Welcome!') }}</p>
    <p class="welcome-modal__secondary-text">{{ $gettext('A new web experience, same great interpreters') }}</p>
    <p class="welcome-modal__regular-text">{{ $gettext('Based on feedback and user testing, we have updated the platform experience. Booking interpreters and conducting assignments have never been easier.') }}</p>
    <p class="welcome-modal__regular-text">{{ $gettext('If you have any questions, feel free to contact us through the chat icon in the bottom right corner.') }}</p>
    <button class="sk-btn sk-btn--default welcome-modal__btn"
            @click="$emit('closemodal')">{{ $gettext('Got it!') }}</button>
  </article>
</template>)

<script>
  export default {};
</script>

<style scoped>
  .welcome-modal {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: -15px;
    padding: 45px 40px 30px;
    background-color: #fff;
    text-align: center;
  }

  .welcome-modal__logo-cont {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  .welcome-modal__logo {
    display: block;
    width: 75px;
    height: 75px;
    background-image: url(~@assets/imgs/logos/single_logo_red.svg);
    background-image: var(--image-single-logo);
    background-position: 50% 50%;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .welcome-modal__main-text {
    font-size: 25px;
  }

  .welcome-modal__secondary-text {
    margin-bottom: 25px;
    font-size: 16px;
  }

  .welcome-modal__regular-text {
    margin-bottom: 20px;
    font-size: 12px;
  }

  .welcome-modal__btn {
    width: auto;
    margin-top: 10px;
    padding: 0 60px;
  }
</style>
