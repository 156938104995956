// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/logos/single_logo_red.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.welcome-modal[data-v-0680540a] {\n  position: relative;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  width: 100%;\n  margin-top: -15px;\n  padding: 45px 40px 30px;\n  background-color: #fff;\n  text-align: center;\n}\n.welcome-modal__logo-cont[data-v-0680540a] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  margin-bottom: 30px;\n}\n.welcome-modal__logo[data-v-0680540a] {\n  display: block;\n  width: 75px;\n  height: 75px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-image: var(--image-single-logo);\n  background-position: 50% 50%;\n  background-size: auto 100%;\n  background-repeat: no-repeat;\n}\n.welcome-modal__main-text[data-v-0680540a] {\n  font-size: 25px;\n}\n.welcome-modal__secondary-text[data-v-0680540a] {\n  margin-bottom: 25px;\n  font-size: 16px;\n}\n.welcome-modal__regular-text[data-v-0680540a] {\n  margin-bottom: 20px;\n  font-size: 12px;\n}\n.welcome-modal__btn[data-v-0680540a] {\n  width: auto;\n  margin-top: 10px;\n  padding: 0 60px;\n}\n", ""]);
// Exports
module.exports = exports;
